<template>
  <div class="flex-row-fluid ml-lg-12">
    <div class="card card-custom card-stretch">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">手机号码管理</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">管理账号的手机号码</span>
        </div>
        <div class="card-toolbar">
          <button type="reset" class="btn btn-success" v-on:click="saveInfo">保存</button>
        </div>
      </div>
      <div class="container" style="max-width: 70%">
        <form class="form">
          <div class="card-body">
            <div class="form-group row align-items-center">
              <div class="col-lg-12 col-xl-9">
                <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success">
                  <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>现手机号码：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control" v-model="oldPhone" type="text" placeholder="手机号码" name="phone" autocomplete="off" disabled/>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>手机号码：</label>
              <div class="col-lg-9 col-xl-6">
                <div class="input-group">
                  <input class="form-control" v-model="phone" type="text" placeholder="手机号码" name="phone" autocomplete="off" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" v-on:click="validatePhone">发送</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>验证码：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control" v-model="user.phoneCode" type="text" placeholder="验证码" name="phoneCode" autocomplete="off" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/core/services/store";
import {UPDATE_USER} from "@/core/services/store/auth.module";
export default {
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    }
  },
  mounted() {
    this.oldPhone = this.user.phone;
    this.phone = "";
  },
  data() {
    return {
      errors: [],
      msgs: [],
      oldPhone: "",
      phone: "",
    };
  },
  methods: {
    validatePhone: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (this.phone && this.phone.length === 11) {
          this.user.phone = this.phone;
          this.user.oldPhone = this.oldPhone;
          // 请求后台获取验证码
          this.$axios.fetchPost("/hr/user/validatePhone", this.user).then(data => {
            if (data.data && data.data.res === "noDb") {
              this.errors.push("企业平台错误");
            } else if (data.data && data.data.res === "exist") {
              this.errors.push("该手机号码已经注册");
            } else if (data.data && data.data.res === "success"){
              this.user.code = data.data.code;
              this.msgs.push("短信发送成功");
              return;
            }else {
              this.errors.push("请刷新重试");
            }
          })
              .catch(err => {
                console.log(err);
              });
        } else {
          this.errors.push("手机号码错误");
        }
      } else {
        this.errors.push("登录已过期，请刷新页面");
      }
    },
    saveInfo() {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.recordId){
        if (!this.phone){
          this.errors.push("请注意必须填写手机号码");
          return;
        }
        if (!(this.phone.length === 11)){
          this.errors.push("请填写正确的手机号码");
          return;
        }
        if (!this.user.phoneCode){
          this.errors.push("请注意必须填写验证码");
          return;
        }
        this.user.phone = this.phone;
        this.user.oldPhone = this.oldPhone;
        this.$axios.fetchPost("hr/user/editPhone",this.user).then(result => {
          if (result.data === "noDb") {
            this.errors.push("企业平台错误");
          } else if (result.data === "fail") {
            this.errors.push("短信验证码错误");
          } else if (result.data === "exist") {
            this.errors.push("手机号码已经存在");
          } else if (result.data === "success") {
            this.oldPhone = this.phone;
            this.phone = "";
            this.msgs.push("修改成功，请通知OA管理员变更员工手机号码！");
            store.dispatch(UPDATE_USER);
          } else {
            this.errors.push("请刷新重试");
          }
        }).catch(err => {console.log(err);});
      }else {
        this.errors.push("登录已过期，请刷新页面");
      }
    },
  }
}
</script>